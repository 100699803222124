#global-loader {
  height: 100dvh;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
}

#global-loader.auth {
  height: calc(100dvh - 190px);
}

.grid-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  position: relative;
  width: 64px;
  height: 60px;
}

.loader::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  background: #364FB3;
  width: 64px;
  height: 32px;
  border-radius: 0 0 50px 50px;
  animation: move 0.5s linear infinite alternate;
}

.loader::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  background: #ff6900;
  width: 24px;
  height: 24px;
  transform: translateX(-50%) rotate(0deg);
  animation: rotate 2s linear infinite;
}

.loading-text::after {
  content: "Loading...";
  font-size: 24px;
  animation: loadingAnimation 2s linear infinite;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

@keyframes loadingAnimation {
  0% {
    content: "Loading...";
  }

  25% {
    content: "Loading";
  }

  50% {
    content: "Loading.";
  }

  75% {
    content: "Loading..";
  }
}

@keyframes rotate {
  100% {
    transform: translateX(-50%) rotate(360deg)
  }
}

@keyframes move {
  0% {
    transform: rotate(10deg)
  }

  100% {
    transform: rotate(-10deg)
  }
}